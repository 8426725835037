<template>
  <div class="developing">
    <p>开发中...</p>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {},
};
</script>

<style lang="scss">
.developing {
  height: 100%;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
